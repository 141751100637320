<template>
  <main-wrapper detail tool-background-color="#409EFF" tool-color="#FFF" class="page__detail">
    <template slot="tool">
      <el-button type="primary" class="flat ipx-0 body-1 el-button--white" @click="$router.go(-1)" v-ripple>
        <i class="el-icon-back im-1"></i>
      </el-button>
      <span class="sub-title ipx-1">{{mode===ADD ? '新增用户' : '修改用户'}}</span>
    </template>

    <div class="detail__main clearfix" v-loading="!mountedFlag">
      <div class="card-block">
        <p class="block__title">
          用户信息
        </p>
        <div class="block__body">
          <el-tabs >
            <el-tab-pane label="基本">
              <el-form ref="form" :rules="rules" :model="form" label-width="80px">
                <el-form-item label="用户名称" prop="HoldName">
                  <el-input v-model="form.HoldName" class="v-input"></el-input>
                </el-form-item>
                 <el-form-item label="所属用户" v-if=" form.ParentHoldName != 'SSAdmin' ">
                  <span class="">{{form.ParentHoldName}}</span>
                  <!-- <el-input v-model="form.ParentHoldName" class="v-input"></el-input> -->
                </el-form-item>
                <el-form-item label="登录账号" prop="UserName">
                  <el-input v-model="form.UserName" class="v-input" :disabled="mode!=ADD"></el-input>
                </el-form-item>
                <el-form-item label="登录密码" prop="UserPassword">
                  <el-input v-model="form.UserPassword" :type="(loginInfo.HoldID===1 || loginInfo.UserID===11174) ? 'text' : 'password'" class="v-input"></el-input>
                </el-form-item>
                <el-form-item label="联系人" prop="Contacter">
                  <el-input v-model="form.Contacter" class="v-input"></el-input>
                </el-form-item>
                <el-form-item label="联系手机" prop="Tel">
                  <el-input v-model="form.Tel" class="v-input"></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="Remark">
                  <el-input type="textarea" v-model="form.Remark"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="okHandle">提交</el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="权限">
              <el-tree
                :check-strictly="true"
                ref="tree"
                :data="fun"
                show-checkbox
                node-key="id"
                :default-checked-keys="hasFun"
                :props="defaultProps"
                @check="checkHandler">
              </el-tree>
            </el-tab-pane>
          </el-tabs>

        </div>
      </div>
    </div>
  </main-wrapper>
</template>

<script>
import * as API from '@/api/hold'
import mixin from './mixin-detail'
import {GetFunTree} from '@/api/user'

const ADD = 'ADD'
const MODIFY = 'MODIFY'
export default {
  props: {
    itemId: {
      type: String,
      default: null
    }
  },
  mixins: [mixin],
  data() {
    return {
      ADD,
      MODIFY,
      form: {
        HoldID: null,
        HoldName: '',
        ParentHoldID: null,
        ParentHoldName: '',
        UserName: '',
        UserPassword: '',
        UserPasswordAgain: '',
        Contacter: '',
        Tel: '',
        Remark: null
      },
      rules: {
        HoldName: [ {required: true,message:'请输入用户名称',trigger: 'blur'} ],
        UserName: [ {required: true,message:'请输入登录账号',trigger: 'blur'} ],
        UserPassword: [ {required: true,message:'请输入登录密码',trigger: 'blur'} ],
        Contacter: [ {required: true,message:'请输入联系人名称',trigger: 'blur'} ],
        Tel: [ {required: true,message:'请输入联系人电话',trigger: 'blur'} ],
        // Remark: [{required: true, message: '请再次输入密码'},{validator:this.validatePass, trigger: 'blur'}]
      },
      fun: [],
      hasFun: [],
      defaultProps: {
        id: 'id',
        children: 'children',
        label: 'label',
        disabled() {
          return false
        }
      }
    }
  },
  computed: {
    mode() {
      return this.itemId === null ? ADD : MODIFY
    },
    isSelf() {
      return this.itemId == this.loginInfo.HoldID && this.loginInfo.UserType === 951
    }
  },
  created() {
    if (this.mode == ADD) {
      this.mountedFlag = true
      this.form.ParentHoldID = this.currentHoldId
      this.form.ParentHoldName = this.currentHoldName
      this.fetchFun()
    } else {
      this.fetchData()
    }

    this.defaultProps.disabled = () => {
      return this.isSelf
    }
  },
  methods: {
    onSubmit() {
      console.log('submit!');
    },
    fetchData() {
      API.QueryHoldDetail(this.itemId, this.currentHoldId).then(ret => {
        this.form = Object.assign(this.form, ret.data.hold)
        this.fun = ret.data.fun.slice()
        this.hasFun = ret.data.hasFun.slice()
        this.mountedFlag = true
      })
    },
    fetchFun() {
      GetFunTree(this.form.ParentHoldID, 1).then(ret => {
        this.fun = ret.data.fun.slice()
        this.hasFun = ret.data.hasFun.slice()
      })
    },
    async okHandle() {
      try {
        await this.valid()
      } catch (e) {
        return
      }
      const newFun = this.$refs.tree.getCheckedKeys()
      if (this.mode == ADD) {
        API.AddHold(this.form, newFun).then(ret => {
            if (!ret.data.errCode) {
              this.$emit('refresh')
              this.$router.go(-1)
            } else {
              // 错误
              this.print('error', ret.data.errMsg)
            }
        }).catch(err => {
          // 错误
          this.print('error', err.message)
        })
      } else {
        API.Modify(this.form, newFun).then(ret => {
            if (!ret.data.errCode) {
              this.$message({
                message: this.$t('common.success'),
                type: 'success'
              })
              this.$emit('refresh')
              this.$router.go(-1)
            } else {
              // 错误
              this.print('error', ret.data.errMsg)
            }
        }).catch(err => {
          // 错误
          this.print('error', err.message)
        })
      }


    },
    valid() {
      const promise = new Promise((resolve, reject) => {
        this.$refs.form.validate(valid => {
          if (valid) {
            resolve(true)
          } else {
            reject(false)
          }
        })
      })
      return promise
    },
    checkHandler(node, {checkedKeys, checkedNodes}) {
      console.log(node)
      // 判断节点是否被选中
      const isChecked = checkedNodes.includes(node)
      console.log('是否选中', isChecked)

      // 获取所有子节点
      const allChildrenKeys = []
      const getChildrenKeys = (node) => {
        if (!node.children) {
          return allChildrenKeys
        } else {
          node.children.forEach(j => {
            allChildrenKeys.push(j.id)// 添加子节点
            getChildrenKeys(j)
          })
        }
      }
      getChildrenKeys(node)
      console.log('所有子节点', allChildrenKeys)

      // checkedKeys中移除或添加后，设置选中节点
      if (isChecked) {
        allChildrenKeys.forEach(k => {
          if (!checkedKeys.includes(k)) {
            checkedKeys.push(k)
          }
        })
      } else {
        allChildrenKeys.forEach(k => {
          if (checkedKeys.includes(k)) {
            const index = checkedKeys.indexOf(k)
            checkedKeys.splice(index, 1)
          }
        })
      }

      this.$refs.tree.setCheckedKeys(checkedKeys)
    }
  }
}
</script>

<style lang="scss" scoped>
.title,.sub-title  {
  color: inherit;
}
.v-input {
  max-width: 215px;
}
.detail__main {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  .card-block {
    margin: 12px auto 0;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;

    .block__title {
      line-height: 38px;
      height: 38px;
      padding: 0 16px;
      border-bottom: 1px solid #DCDFE6;
      color: #303133;
    }
    .block__body {
      padding: 12px 24px;
    }
  }
}

@media screen and (min-width: 1272px) {
  .detail__main {
    //max-width: 1296px;
    .card-block {
      width: 600px;
      margin: 12px 0 0 24px;
    }
  }
}

.el-tab-pane {
  height: 500px;
  overflow-y: auto;
}
</style>
